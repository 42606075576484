import { backgroundPlaceholder } from '../../utils/constants.js';
import { replaceUrlEncodedEntities } from '../../utils/stringHelpers.js';
import { Coach, CoachLocation, PublicCoach } from '../coach.js';
import { CoachProfile } from '../coach/profile.js';
import type { MarketplaceToutType } from '../tout.js';
import { UserName } from '../user.js';
import { DbAvailability, fromDbAvailability } from './availability.js';
import { DbRecurringEvent, fromDbRecurringEvents } from './coach/event.js';
import type {
  DbCoachExperiencePlaying,
  DbCoachExperienceTeaching,
  DbMarketplaceCoachExperience,
  DbMarketplaceCoachPlayingExperience,
  DbMarketplaceCoachTeachingExperience,
} from './coach/experience.js';
import {
  DbCoachBusinessLogo,
  DbCoachCoverPhoto,
  DbCoachPhoto,
  DbElasticSearchCoachPhoto,
  fromAPIPhoto,
  fromDbElasticSearchCoachPhoto,
  fromDbPhoto,
} from './coach/media.js';
import {
  DbCoachAchievement,
  DbCoachCertification,
  DbCoachCollege,
  DbCoachPreferredStudentSkillLevel,
  DbCoachProfile,
  DbCoachSite,
  DbCoachSport,
  DbElasticSearchCoachAchievement,
  DbMarketplaceTout,
  DbMarketplaceToutType,
  fromDbCoachProfile,
  fromDbMarketplaceTout,
  fromDbToutType,
} from './coach/profile.js';
import { DbCoachRates, fromDbCoachRates } from './coach/rates.js';
import { DbCoachReview, fromDbCoachReview } from './coach/review.js';
import { DbCollege, fromDbCollege } from './college.js';
import {
  DbElasticSearchLocation,
  DbLocation,
  DbMarketplaceLocation,
  fromDbElasticSearchLocation,
  fromDbLocation,
  fromDbMarketplaceLocation,
} from './location.js';
import { DbAPIMedia, DbPhoto2, fromDbPhoto2 } from './mediafile.js';
import { DbMembership, fromDbMembership } from './membership.js';
import { DbPackage, fromDbPackage } from './package.js';
import type { DbElasticSearchSport, DbMarketplaceSport } from './sport.js';

export type DbCoach = {
  golfpro_id: string;
  golfpro_photo: DbCoachPhoto;
  golfpro_coverphoto: DbCoachCoverPhoto;
  coach_businesslogo: DbCoachBusinessLogo;
  golfpro_profile: DbCoachProfile;
  golfpro_site: DbCoachSite;
  golfpro_achievement: DbCoachAchievement[];
  golfpro_experience_playing: DbCoachExperiencePlaying[];
  golfpro_experience_teaching: DbCoachExperienceTeaching[];
  coach_certification: Record<string, DbCoachCertification>;
  coach_preferred_student_skill_level: DbCoachPreferredStudentSkillLevel[];
  coach_college: DbCoachCollege[];
  coach_sport: Record<string, DbCoachSport>;

  first_name: string;
  last_name: string;
  display_name: string;

  golfpro_golfcourse: DbCoachLocation[];
};

// This is a golfpro_golfcourse
export type DbCoachLocation = {
  is_primary: string; // 0 or 1
  radius: string;
  encrypted_id?: string;
  is_admin?: boolean;
  is_group_pro?: boolean;
  is_group_facility?: boolean;
  encrypted_group_facility_id?: string;

  golfcourse: DbLocation;
};

export const fromDbCoach = (dbCoach: DbCoach): Coach => {
  const coach = new Coach();
  coach.id = dbCoach.golfpro_id;
  coach.photo = fromDbPhoto(dbCoach.golfpro_photo);

  if (dbCoach.golfpro_coverphoto) {
    coach.coverPhoto = fromDbPhoto(dbCoach.golfpro_coverphoto);
  } else {
    coach.coverPhoto = {
      url: backgroundPlaceholder,
    };
  }

  if (dbCoach.coach_businesslogo) {
    coach.businessLogo = fromDbPhoto({
      profile_photo_base_url: dbCoach.coach_businesslogo.image_base_url,
      profile_photo_large: dbCoach.coach_businesslogo.image_large,
    });
  }

  if (dbCoach.golfpro_profile) {
    coach.profile = fromDbCoachProfile(dbCoach.golfpro_profile);
  }
  coach.name = new UserName(
    dbCoach.display_name,
    dbCoach.first_name,
    dbCoach.last_name,
  );
  coach.publicSitePath = dbCoach.golfpro_site.golfpro_site_url;

  coach.experiencePlayingIds =
    dbCoach.golfpro_experience_playing == null
      ? []
      : dbCoach.golfpro_experience_playing.map(
          (dbExp) => dbExp.experience_playing.experience_playing_id,
        );
  coach.experienceTeachingIds =
    dbCoach.golfpro_experience_teaching == null
      ? []
      : dbCoach.golfpro_experience_teaching.map(
          (dbExp) => dbExp.experience_teaching.experience_teaching_id,
        );

  coach.achievements =
    dbCoach.golfpro_achievement == null
      ? []
      : dbCoach.golfpro_achievement.map((ach) => ach.achievement_str);
  coach.certifications =
    dbCoach.coach_certification == null
      ? []
      : Object.values(dbCoach.coach_certification).map(
          (dbCert) => dbCert.certification_text,
        );
  coach.sportIds =
    dbCoach.coach_sport == null
      ? []
      : Object.values(dbCoach.coach_sport).map(
          (dbCoachSport) => dbCoachSport.sport_id,
        );
  coach.preferredStudentSkillLevelIds =
    dbCoach.coach_preferred_student_skill_level == null
      ? []
      : dbCoach.coach_preferred_student_skill_level.map(
          (dbSkillLevel) => dbSkillLevel.skill_level_id,
        );

  coach.colleges =
    dbCoach.coach_college == null
      ? []
      : dbCoach.coach_college.map((dbCollege) =>
          fromDbCollege(dbCollege.college),
        );

  if (dbCoach.golfpro_golfcourse) {
    coach.locations = dbCoach.golfpro_golfcourse.map(
      (dbCoachLocation) => fromDbCoachLocation(dbCoachLocation).location,
    );
  }

  return coach;
};

/** This is what is returned in the `/api/marketplaceProfiles` response */
export type DbMarketplaceCoach = {
  id: string;
  user_id: string;
  name: string;
  site_url: string;
  profile: DbCoachProfile;
  avatar: DbAPIMedia;
  cover?: DbAPIMedia;
  locations?: DbMarketplaceLocation[];
  sports: DbMarketplaceSport[];
  availability?: DbAvailability[];
  packages: DbPackage[];
  memberships: DbMembership[];
  recurring_events: DbRecurringEvent[];
  reviews: DbCoachReview[];
  review_totals: {
    review_count: number;
    total_star_count: number;
    average_stars: number;
  };
  // needs to be implemented on the backend so it is part of the API response
  achievements: DbCoachAchievement[];
  experience: DbMarketplaceCoachExperience;
  certifications: Record<string, DbCoachCertification>;
  preferred_student_skill_levels: DbCoachPreferredStudentSkillLevel[];
  colleges: DbCollege[];

  touts?: DbMarketplaceToutType[];
};

export const fromApiCoach = (dbCoach: DbMarketplaceCoach): PublicCoach => {
  const coach = new PublicCoach();

  coach.id = dbCoach.id;
  coach.coachId = dbCoach.id.substring('golfpro_id-'.length);
  coach.userId = dbCoach.user_id;

  if (dbCoach.avatar != null) {
    coach.photo = fromAPIPhoto(dbCoach.avatar);
  }
  if (dbCoach.cover != null) {
    coach.coverPhoto = fromAPIPhoto(dbCoach.cover);
  }

  coach.name = new UserName(dbCoach.name);
  if (dbCoach.profile) {
    coach.profile = fromDbCoachProfile(dbCoach.profile);
  }
  coach.publicSitePath = dbCoach.site_url;

  if (dbCoach.locations) {
    coach.locations = dbCoach.locations.map((dbLocation) =>
      fromDbMarketplaceLocation(dbLocation),
    );
  }
  coach.sports = dbCoach.sports.map((dbSport) => dbSport.name);

  if (dbCoach.availability) {
    coach.availabilities = dbCoach.availability.map((dbAvailability) =>
      fromDbAvailability(dbAvailability),
    );
  }

  if (dbCoach.packages) {
    coach.packages = dbCoach.packages.map((dbPackage) =>
      fromDbPackage(dbPackage),
    );
  }
  if (dbCoach.memberships) {
    coach.memberships = dbCoach.memberships.map((dbMembership) =>
      fromDbMembership(dbMembership),
    );
  }
  if (dbCoach.recurring_events) {
    coach.events = fromDbRecurringEvents(dbCoach.recurring_events);
  }

  coach.reviews = [];
  if (dbCoach.reviews != null) {
    coach.reviews = dbCoach.reviews.map((dbReview) =>
      fromDbCoachReview(dbReview),
    );
    // sort them too
    coach.reviews.sort((a, b) => a.compareTo(b));
  }
  coach.numReviews = dbCoach.review_totals.review_count;
  coach.averageReview = dbCoach.review_totals.average_stars;

  coach.achievements =
    dbCoach.achievements == null
      ? []
      : dbCoach.achievements.map((ach) =>
          replaceUrlEncodedEntities(ach.achievement_str),
        );
  coach.certifications =
    dbCoach.certifications == null
      ? []
      : Object.values(dbCoach.certifications).map((dbCert) =>
          replaceUrlEncodedEntities(dbCert.certification_text),
        );
  if (dbCoach.experience?.playing) {
    coach.experiencePlaying = dbCoach.experience.playing.map((xpPlaying) =>
      replaceUrlEncodedEntities(xpPlaying.name),
    );
  }
  if (dbCoach.experience?.teaching) {
    coach.experienceTeaching = dbCoach.experience.teaching.map((xpTeaching) =>
      replaceUrlEncodedEntities(xpTeaching.name),
    );
  }
  if (dbCoach.preferred_student_skill_levels) {
    coach.preferredStudentSkillLevelIds =
      dbCoach.preferred_student_skill_levels.map(
        (dbSkillLevel) => dbSkillLevel.skill_level_id,
      );
  }

  if (dbCoach.touts) {
    coach.touts = dbCoach.touts.map((dbToutType) => {
      const toutType = fromDbToutType(dbToutType);
      const marketplaceToutType = toutType as MarketplaceToutType;
      marketplaceToutType.touts = dbToutType.touts.map(
        (dbTout: DbMarketplaceTout) => fromDbMarketplaceTout(dbTout, toutType),
      );

      return marketplaceToutType;
    });
  }

  if (dbCoach.colleges) {
    coach.colleges = dbCoach.colleges.map((dbCollege) =>
      fromDbCollege(dbCollege),
    );
  }

  return coach;
};

export const fromDbCoachLocation = (
  dbCoachLocation: DbCoachLocation,
): CoachLocation => {
  const coachLocation: CoachLocation = {
    isPrimary: dbCoachLocation.is_primary === '1',
    encryptedId: dbCoachLocation.encrypted_id,
    isAdmin: dbCoachLocation.is_admin,
    isGroupPro: dbCoachLocation.is_group_pro,
    encryptedGroupFacilityId: dbCoachLocation.encrypted_group_facility_id,

    location: fromDbLocation(dbCoachLocation.golfcourse),
  };

  if (dbCoachLocation.radius) {
    coachLocation.radius = parseInt(dbCoachLocation.radius, 10);
  }

  return coachLocation;
};

export type DbCoach2 = {
  id: string;
  first_name: string;
  last_name: string;
  display_name: string;

  site_url: string;

  media?: DbPhoto2[];
};

export const fromDbCoach2 = (dbCoach: DbCoach2): Coach => {
  const coach = new Coach();
  coach.id = dbCoach.id;
  coach.coachId = dbCoach.id.substring('golfpro_id-'.length);
  coach.name = {
    firstName: dbCoach.first_name,
    lastName: dbCoach.last_name,
    displayName: dbCoach.display_name,
  };
  coach.publicSitePath = dbCoach.site_url;

  if (dbCoach.media?.length) {
    coach.photo = fromDbPhoto2(dbCoach.media[0]);
  }

  return coach;
};

export type DbElasticSearchCoach = {
  id: number;
  name: string;
  site: string;
  avatar: DbElasticSearchCoachPhoto;
  cover: DbElasticSearchCoachPhoto;
  experience: {
    playing: DbMarketplaceCoachPlayingExperience[] | null;
    teaching: DbMarketplaceCoachTeachingExperience[] | null;
  };
  achievements: DbElasticSearchCoachAchievement[] | null;
  locations: DbElasticSearchLocation[];
  profile: {
    bio: string;
  }[];
  rates: DbCoachRates;
  review_totals: {
    average_stars: number;
    review_count: number;
    star_count: number;
  };
  sports: DbElasticSearchSport[];
};

export const fromDbElasticSearchCoach = (
  dbCoach: DbElasticSearchCoach,
): PublicCoach => {
  const coach = new PublicCoach();
  try {
    coach.id = `${dbCoach.id}`;
    coach.coachId = coach.id;
    coach.name = {
      displayName: dbCoach.name,
    };
    coach.publicSitePath = dbCoach.site;

    if (dbCoach.avatar != null) {
      coach.photo = fromDbElasticSearchCoachPhoto(dbCoach.avatar);
    }
    if (dbCoach.cover != null) {
      coach.coverPhoto = fromDbElasticSearchCoachPhoto(dbCoach.cover);
    }
    coach.locations = dbCoach.locations.map((dbLocation) =>
      fromDbElasticSearchLocation(dbLocation),
    );
    coach.sports = dbCoach.sports?.map((dbSport) => dbSport.name);
    if (dbCoach.profile?.length) {
      coach.profile = new CoachProfile();
      coach.profile.bio = dbCoach.profile[0].bio;
    }
    coach.experienceTeaching = dbCoach.experience.teaching?.map(
      (et) => et.name,
    );

    coach.rates = fromDbCoachRates(dbCoach.rates);

    if (dbCoach.review_totals?.review_count != null) {
      coach.numReviews = dbCoach.review_totals.review_count;
    }
    if (dbCoach.review_totals?.average_stars != null) {
      coach.averageReview = dbCoach.review_totals.average_stars;
    }
  } catch (e) {
    console.error(e);
  }

  return coach;
};
