export const replaceUrlEncodedEntities = (input: string) =>
  input.replace(/&#039;/g, "'").replace(/&amp;/g, '&');

export const formatAsCurrency = (input: number) => {
  const usDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return usDollar.format(input);
};
