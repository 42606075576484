import { Star } from '@thrivesports/design-system';
import {
  avatarPlaceholder,
  buildDomainURL,
  PublicCoach,
} from '@thrivesports/shared';
import React, { useContext } from 'react';

import { AppAttrsCtx } from '../context/AppAttrsCtx.js';
import { useClosestLocation } from '../hooks/useClosestLocation.js';
import { allSports } from '../hooks/useDefaultSearchAPI.js';
import { useSearchParams } from '../hooks/useSearchParams.js';
import { DistanceFrom } from './DistanceFrom.js';

export type CoachRowProps = {
  coach: PublicCoach;
  className?: string;
};

export const CoachCard: React.FC<CoachRowProps> = ({ coach }) => {
  const appAttributes = useContext(AppAttrsCtx);

  const closestLoc = useClosestLocation(coach.locations);

  const { sport: searchedSport } = useSearchParams();
  const hasSportFilter = searchedSport?.length && searchedSport !== allSports;

  return (
    <div className="tssm-coach-card">
      <a
        href={buildDomainURL(
          appAttributes.datasiteDomain,
          coach.publicSitePath,
        )}
        className="tssm-coach-card-link-wrapper"
      >
        <div className="ts-card tssm-coach-card-inner">
          <div className="tssm-coach-row-avatar-ctn">
            <img
              src={coach.photo.url || avatarPlaceholder}
              className="tssm-coach-row-avatar"
            />
          </div>
          <div className="tssm-coach-card-content">
            <div className="tssm-coach-card-row">
              <p className="tssm-coach-card-name">{coach.name.displayName}</p>{' '}
              {coach.averageReview > 0 ? (
                <div className="tssm-coach-card-rating">
                  <Star />
                  <span className="tssm-coach-card-rating-inner">
                    {coach.averageReview > 0
                      ? coach.averageReview.toFixed(1)
                      : 0}
                  </span>
                </div>
              ) : null}
            </div>
            {closestLoc ? (
              <>
                <div className="tssm-coach-card-row">
                  <p className="tssm-coach-card-loc-name">
                    {closestLoc?.getName()}
                  </p>
                  {coach.numReviews > 0 ? (
                    <span className="tssm-coach-card-num-reviews">
                      ({coach.numReviews}{' '}
                      {coach.numReviews === 1 ? 'review' : 'reviews'})
                    </span>
                  ) : null}
                </div>
                <div className="tssm-coach-card-row">
                  <DistanceFrom closestLocation={closestLoc} />
                  {!hasSportFilter && coach.sports?.length ? (
                    <span className="tsb-profile-sports ts-section-text-highlight">
                      {coach.sports.length > 1
                        ? `${coach.sports[0]} +${coach.sports.length - 1}`
                        : `${coach.sports[0]}`}
                    </span>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </a>
    </div>
  );
};
