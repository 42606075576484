import { backgroundPlaceholder } from '@thrivesports/shared';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';

import { AppAttrsCtx } from '../context/AppAttrsCtx.js';
import type { SearchResultsEventHitInterface } from '../utils/types.js';

export interface EventCardPropsInterface {
  event: SearchResultsEventHitInterface;
}

const EventCard = (props: EventCardPropsInterface) => {
  const AppAttributes = useContext(AppAttrsCtx);

  // eslint-disable-next-line no-underscore-dangle
  const eventSource = props.event._source;

  const [nextBookDate, setNextBookDate] = useState<DateTime | null>(null);
  const [bookedCount, setBookedCount] = useState<number>(0);
  const [cost, setCost] = useState<number | null>(null);
  const [isCostForAdults, setIsCostForAdults] = useState<boolean>(false);
  const [coaches, setCoaches] = useState<Array<string>>([]);
  const [bookingAvail, setBookingAvail] = useState<boolean>(false);

  useEffect(() => {
    let nextBookDateRaw: DateTime | null = null;
    let bookedCountRaw = 0;
    let costRaw: number | null = null;
    let isCostForAdultsRaw = false;
    let coachesRaw: Array<string> = [];
    // Go through schedule to compare bookings and get the most recent and accurate booking and pricing
    if (eventSource.schedule) {
      eventSource.schedule.forEach((schedule) => {
        if (schedule.booking) {
          schedule.booking.forEach((booking) => {
            const newBookDate = DateTime.fromSQL(booking.booked_date);
            if (nextBookDateRaw == null) {
              nextBookDateRaw = DateTime.fromSQL(booking.booked_date);
              bookedCountRaw = booking.booked_count;
              coachesRaw = schedule.coaches.map(
                (scheduleCoach) =>
                  // if(scheduleCoach.coach_type == 'primary'){
                  // headCoachRaw = scheduleCoach; // Not used as of now since retrieving data about coach is costly
                  // }
                  scheduleCoach.display_name, // Get coach's display name
              );
            } else if (
              newBookDate < nextBookDateRaw &&
              booking.booked_count < schedule.max_athletes
            ) {
              nextBookDateRaw = DateTime.fromSQL(booking.booked_date);
              bookedCountRaw = booking.booked_count;
              coachesRaw = schedule.coaches.map(
                (scheduleCoach) => scheduleCoach.display_name,
              );
            }
          });
        }
        if (schedule.rates) {
          schedule.rates.forEach((rate) => {
            if (costRaw == null) {
              costRaw = rate.rate;
            } else if (
              isCostForAdultsRaw &&
              rate.golfer_type_str === 'Adult' &&
              rate.rate < costRaw
            ) {
              costRaw = rate.rate;
              isCostForAdultsRaw = true;
            } else if (
              !isCostForAdultsRaw &&
              rate.golfer_type_str !== 'Adult' &&
              rate.rate < costRaw
            ) {
              costRaw = rate.rate;
            }
          });
        }
      });
    }
    setNextBookDate(nextBookDateRaw);
    setBookedCount(bookedCountRaw);
    setCost(costRaw);
    setIsCostForAdults(isCostForAdultsRaw);
    setCoaches(coachesRaw);
    if (parseInt(`${eventSource.max_athletes}`, 10) - bookedCountRaw > 0) {
      setBookingAvail(true);
    }
  }, [eventSource.max_athletes, eventSource.schedule]);

  return (
    <div className="tssm-event-card">
      <a
        href={new URL(
          eventSource.site,
          AppAttributes.datasiteDomain,
        ).toString()}
        rel="noreferrer"
        className="tssm-event-card-link-wrapper"
      >
        <div className="tssm-event-card-inner ">
          <div
            className="tssm-event-card-bg"
            style={{
              backgroundImage: `url('${
                eventSource.artifacts[0].path || backgroundPlaceholder
              }')`,
            }}
          />
          <div className="tssm-event-card-content">
            <div className="tssm-event-card-left">
              <div className="tssm-event-card-info">
                <div className="tssm-event-card-tickets">
                  {Number(eventSource.max_athletes) - bookedCount > 0 && (
                    <>
                      <span className="tsi-ticket" />
                      <span className="tssm-event-card-tickets-available">
                        {Number(eventSource.max_athletes) - bookedCount}
                      </span>
                      <span className="tssm-event-card-tickets-available-txt">
                        &nbsp;available
                      </span>
                    </>
                  )}
                </div>
                <p className="tssm-event-card-title">{eventSource.title}</p>
              </div>
            </div>
            <div className="tssm-event-card-right">
              <div className="tssm-event-card-date-circle">
                <div className="tssm-event-card-date-day">
                  {nextBookDate && <>{nextBookDate.toFormat('dd')}</>}
                </div>
                <div className="tssm-event-card-date-month">
                  {nextBookDate && <>{nextBookDate.toFormat('MMM')}</>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};
export default EventCard;
