import { CoachRates } from '../../coach/rates.js';

export type DbCoachRates = {
  avg: number;
  min: number;
  max: number;
};

export const fromDbCoachRates = (dbCoachRates: DbCoachRates) => {
  const rates = new CoachRates();

  rates.average = dbCoachRates.avg;
  rates.max = dbCoachRates.max;
  rates.min = dbCoachRates.min;

  return rates;
};
