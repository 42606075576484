import type { AttrisetAttrInterface, Sport } from '@thrivesports/shared';

export interface ScriptAttrSuggestedLocationInterface {
  name: string;
  place: string;
  backgroundSrc: string;
  latitude: number;
  longitude: number;
}

export interface ScriptAttrRequirementsInterface {
  partner: string;
  partnerCode: string;
  targetApi: string;
  contentInjectionSiteClassName: string;
  navbarInjectionSiteClassName: string;
  navbarTargetPath: string;
  navbarText: string;
  mapTileSectionInjectionSiteClassName: string;
  coachesSectionInjectionSiteClassName: string;
  eventsSectionInjectionSiteClassName: string;
  suggestedLocationsSectionInjectionSiteClassName: string;
  datasiteDomain: string;
  currentLat?: number;
  currentLng?: number;
  currentCityState?: string;
  currentCityStateOverride?: boolean;
  mapSingleIcon: string;
  mapMultiIcon: string;
  mapCenterIcon: string;
  sportDropdownWhitelist: Sport[];
  suggestedLocations: ScriptAttrSuggestedLocationInterface[];
  mapStyling: any;
  primaryColor?: string;
  secondaryColor?: string;
  borderColor?: string;
  headersColor?: string;
  textPrimary?: string;
  textSecondary?: string;
  animOpenTime?: string;
  animCloseTime?: string;
}

const scriptAttrRequirements: AttrisetAttrInterface[] = [
  {
    attribute: 'partner',
    internalAttribute: 'partner',
    type: 'string',
    description:
      'This is used for ThrivSports internally to know which partner is using the applet.',
  },
  {
    attribute: 'partner-code',
    internalAttribute: 'partnerCode',
    type: 'string',
    description: 'This is important for limiting coaches to a partner.',
  },
  {
    attribute: 'target-api',
    internalAttribute: 'targetApi',
    type: 'string',
    description:
      'Sets the target api for staging or production with the "stage" and "prod" values respectivly.',
  },
  {
    attribute: 'navbar-injection-site-class-name',
    internalAttribute: 'navbarInjectionSiteClassName',
    type: 'string',
    description: 'Changes the class targeted for injecting the navbar',
  },
  {
    attribute: 'navbar-target-path',
    internalAttribute: 'navbarTargetPath',
    type: 'string',
    description:
      'Changes the default location or path of your desired search page. Instead of directing users to the **/search** page, they can be directed to whatever path you specify in the attribute.',
  },
  {
    attribute: 'navbar-text',
    internalAttribute: 'navbarText',
    type: 'string',
    description:
      'The default navbar text that appears in the searchbar for location.',
  },
  {
    attribute: 'content-injection-site-class-name',
    internalAttribute: 'contentInjectionSiteClassName',
    type: 'string',
    description:
      'Changes the default content injection site class to the one provided in the attribute.',
  },
  {
    attribute: 'map-tile-section-injection-site-class-name',
    internalAttribute: 'mapTileSectionInjectionSiteClassName',
    type: 'string',
    description:
      'Changes the default map tile section injection site class to the one provided in the attribute.',
  },
  {
    attribute: 'coaches-section-injection-site-class-name',
    internalAttribute: 'coachesSectionInjectionSiteClassName',
    type: 'string',
    description:
      'Changes the default coaches section injection site class to the one provided in the attribute.',
  },
  {
    attribute: 'events-section-injection-site-class-name',
    internalAttribute: 'eventsSectionInjectionSiteClassName',
    type: 'string',
    description:
      'Changes the default events section injection site class to the one provided in the attribute.',
  },
  {
    attribute: 'suggested-locations-section-injection-site-class-name',
    internalAttribute: 'suggestedLocationsSectionInjectionSiteClassName',
    type: 'string',
    description:
      'Changes the default suggested locations section injection site class to the one provided in the attribute.',
  },
  {
    attribute: 'datasite-domain',
    internalAttribute: 'datasiteDomain',
    type: 'string',
    description:
      'This changes the host of the url for your desired search marketplace when a user clicks on a link or searches.',
  },
  {
    attribute: 'current-lat',
    internalAttribute: 'currentLat',
    type: 'float',
    description:
      'Manually sets the current lat of the user for overriding the location services.',
  },
  {
    attribute: 'current-lng',
    internalAttribute: 'currentLng',
    type: 'float',
    description:
      'Manually sets the current lng of the user for overriding the location services.',
  },
  {
    attribute: 'current-city-state',
    internalAttribute: 'currentCityState',
    type: 'string',
    description:
      'Manually sets the current city and state of the user for overriding the location services.',
  },
  {
    attribute: 'current-city-state-override',
    internalAttribute: 'currentCityStateOverride',
    type: 'boolean',
    description:
      'This boolean value can be true or false and makes the current-city-state attr take presedence for location services.',
  },
  {
    attribute: 'map-single-icon',
    internalAttribute: 'mapSingleIcon',
    type: 'string',
    description: 'A url to the icon used to signify a single coach on the map.',
  },
  {
    attribute: 'map-multi-icon',
    internalAttribute: 'mapMultiIcon',
    type: 'string',
    description:
      'A url to the icon used to signify multiple coaches on the map.',
  },
  {
    attribute: 'map-center-icon',
    internalAttribute: 'mapCenterIcon',
    type: 'string',
    description:
      'A url to the icon used to signify the center of a search radius on the map.',
  },
  {
    attribute: 'sport-dropdown-whitelist',
    internalAttribute: 'sportDropdownWhitelist',
    type: 'jsonFetch',
    description:
      'A url to a json file that contains all the sports to be shown in the sport dropdown.',
  },
  {
    attribute: 'suggested-locations',
    internalAttribute: 'suggestedLocations',
    type: 'jsonFetch',
    description:
      'This expects a url to json content that will be used to customize suggested locations where you are certain that there are services available.',
  },
  {
    attribute: 'map-styling',
    internalAttribute: 'mapStyling',
    type: 'jsonFetch',
    description: 'A url to a json file used to style a google map',
  },
  // Themeing below
  {
    attribute: 'primary-color',
    internalAttribute: 'primaryColor',
    type: 'string',
    description: 'Changes the primary color for the application',
  },
  {
    attribute: 'secondary-color',
    internalAttribute: 'secondaryColor',
    type: 'string',
    description: 'Changes the secondary color for the application',
  },
  {
    attribute: 'border-color',
    internalAttribute: 'borderColor',
    type: 'string',
    description: 'Changes the border color for the application',
  },
  {
    attribute: 'headers-color',
    internalAttribute: 'headersColor',
    type: 'string',
    description: 'Changes the text header color for the application',
  },
  {
    attribute: 'text-primary',
    internalAttribute: 'textPrimary',
    type: 'string',
    description: 'Changes the text color for the application',
  },
  {
    attribute: 'text-secondary',
    internalAttribute: 'textSecondary',
    type: 'string',
    description: 'Changes the text secondary color for the application',
  },
  {
    attribute: 'anim-open-time',
    internalAttribute: 'animOpenTime',
    type: 'string',
    description:
      'Changes some of the opening times on animations for the application',
  },
  {
    attribute: 'anim-close-time',
    internalAttribute: 'animCloseTime',
    type: 'string',
    description:
      'Changes some of the closing times on animations for the application',
  },
];
export default scriptAttrRequirements;
