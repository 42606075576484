import type { SearchResultsEventHitInterface } from './types.js';

export const getMinimumEventPrice = (event: SearchResultsEventHitInterface) => {
  let cost: number | null = null;
  let isCostForAdults = false;
  if (event._source.schedule != null) {
    event._source.schedule.forEach((schedule) => {
      if (schedule.rates != null) {
        schedule.rates.forEach((rate) => {
          if (cost == null) {
            cost = rate.rate;
          } else if (
            isCostForAdults &&
            rate.golfer_type_str === 'Adult' &&
            rate.rate < cost
          ) {
            cost = rate.rate;
            isCostForAdults = true; /* If it's adult pricing, give it prio */
          } else if (
            !isCostForAdults &&
            rate.golfer_type_str !== 'Adult' &&
            rate.rate < cost
          ) {
            /* Only hits this if adult pricing has not already been found. */
            cost = rate.rate;
          }
        });
      }
    });
  }
  return cost;
};
