import './selector.scss';

import React, { useEffect, useRef, useState } from 'react';

export type SelectorOptionsType =
  | string
  | {
      valueOf(): string | number;
      toString(): string;
      comp: React.ReactNode;
    };

export type SelectorIcon = React.FC<{ isOpen?: boolean }>;

export type SelectorProps = {
  options: SelectorOptionsType[];
  defaultSelected?: string | number;
  icon?: SelectorIcon;
  className?: string;
  onOpen?(event: React.MouseEvent): void;
  onClose?(event: React.MouseEvent): void;
  onSelect?(newValue?: any): void;
};

export const Selector: React.FC<SelectorProps> = (props) => {
  const dropRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    if (props.defaultSelected) {
      props.options.forEach((op) => {
        if (props.defaultSelected && op.valueOf() === props.defaultSelected) {
          setSelected(op.toString());
        }
      });
    }
  }, [props, selected]);

  // So that we close the dropdown when they click away
  useEffect(() => {
    const listenerFunction = (event: MouseEvent) => {
      if (dropRef) {
        if (event.target) {
          if (!dropRef.current?.contains(event.target as HTMLElement)) {
            if (props.onClose != null && open) {
              props.onClose(event as unknown as React.MouseEvent);
            }
            setOpen(false);
          }
        }
      }
    };
    window.addEventListener('click', listenerFunction);
    return () => {
      window.removeEventListener('click', listenerFunction);
    };
  }, [props, dropRef, open]);

  return (
    <div
      className={`ts-sel-ctn ${open ? 'active' : ''} ${props?.className ?? ''}`}
    >
      <div
        ref={dropRef}
        onClick={(event: React.MouseEvent) => {
          setOpen(!open);
          if (props.onOpen != null && !open) {
            props.onOpen(event);
          }
        }}
        className="ts-sel"
      >
        <div className="ts-selector-title-ctn">
          <>
            <span className="ts-selector-title">
              {selected && selected.toString()}
            </span>

            {props.icon ? props.icon({ isOpen: open }) : null}
          </>
        </div>
      </div>
      <div className={`ts-selector-items ${open ? 'active' : ''}`}>
        {props.options.map((option, optionIndex) => (
          <div
            onClick={(event: React.MouseEvent) => {
              setSelected(option.toString());
              if (props.onSelect != null) {
                props.onSelect(option.valueOf());
              }
              setOpen(false);
              if (props.onClose != null) {
                props.onClose(event);
              }
            }}
            key={optionIndex}
            className="ts-selector-item"
          >
            {typeof option !== 'string' && <>{option.comp}</>}
            {typeof option === 'string' && option}
          </div>
        ))}
      </div>
    </div>
  );
};
