export class ApiResponse<TResult> {
  success: boolean;
  statusCode: number;
  result?: TResult;
  errorMessage?: string;
  errorCode?: string;
  errorCodes?: string[];

  constructor(success: boolean, statusCode: number) {
    this.success = success;
    this.statusCode = statusCode;
  }

  get hasError(): boolean {
    return (
      this.errorMessage != null ||
      this.errorCode != null ||
      (this.errorCodes != null && this.errorCodes.length > 0)
    );
  }

  isSuccess(): this is ApiResponse<TResult> & { result: TResult } {
    return this.success;
  }
}

export type JsonResponse = Record<string, never> | Record<string, never>[];

export type FormBody = Record<string, FormDataEntryValue | string[]>;
export type UnsanitizedFormBody = Record<
  string,
  FormDataEntryValue | string[] | undefined
>;
export type Params = { [key: string]: string };
export type RequestType =
  | 'multipart/form-data'
  | 'application/x-www-form-urlencoded';

export type RequestOptions = {
  progressListener?: (event: ProgressEvent) => void;
  contentType?: RequestType;
  headers?: Record<string, string>;
};

export class RequestData {
  path: string;
  method: string;
  body?: FormBody;
  options?: RequestOptions;

  constructor(
    path: string,
    method: string,
    body?: FormBody,
    options?: RequestOptions,
  ) {
    this.path = path;
    this.method = method;
    this.body = body;
    this.options = options;
  }

  public static toFormData(body: FormBody): FormData {
    const formBody = new FormData();
    Object.keys(body).forEach((key) => {
      if (Array.isArray(body[key])) {
        (body[key] as string[]).forEach((value) =>
          formBody.append(`${key}[]`, value),
        );
      }
      formBody.append(key, body[key] as string);
    });
    return formBody;
  }

  public static toUrlSearchParams(body: FormBody): URLSearchParams {
    const searchParams = new URLSearchParams();
    Object.keys(body).forEach((key) => {
      if (Array.isArray(body[key])) {
        (body[key] as string[]).forEach((value) =>
          searchParams.append(`${key}[]`, value),
        );
      } else {
        searchParams.append(key, body[key] as string);
      }
    });
    return searchParams;
  }

  // TODO move this into the thrivePhpApiClient
  toFetchOptions(): RequestInit {
    const fetchOptions: RequestInit = {
      method: this.method,
      headers: new Headers({
        'x-thriv-react': 'true',
        ...this.options?.headers,
      }),
      // for requests to the PHP backend, include credentials
      credentials: 'include',
    };
    if (this.body) {
      if (
        this.options?.contentType == null ||
        this.options.contentType === 'application/x-www-form-urlencoded'
      ) {
        (fetchOptions.headers as Headers).append(
          'Content-Type',
          'application/x-www-form-urlencoded',
        );
        fetchOptions.body = RequestData.toUrlSearchParams(this.body);
      } else if (this.options?.contentType === 'multipart/form-data') {
        fetchOptions.body = RequestData.toFormData(this.body);
      }
    }
    return fetchOptions;
  }
}
