import { useEffect, useState } from 'react';

import type { ApiResponse } from '../utils/apiTypes.js';
import { initWindowForThriv } from '../utils/globalHelpers.js';
import {
  keyStatusChanged,
  keyValueChanged,
  LoadStatus,
  SyncManager,
} from '../utils/sync.js';

export type UseSingletonAPIRequestFetcher<T> = () => Promise<ApiResponse<T>>;

export interface UseSingletonAPIRequestResponse<T> {
  status: LoadStatus;
  results?: T;
}

export const useSingletonAPIRequest = <T extends object>(
  key: string,
  fetcher?: UseSingletonAPIRequestFetcher<T>,
): UseSingletonAPIRequestResponse<T> => {
  const syncKey = `FETCH-${key}`;
  // if an EventEmitter already exists on this page, use it
  if (!window.Thriv) {
    initWindowForThriv();
  }
  if (!window.Thriv.Sync) {
    window.Thriv.Sync = {};
  }
  if (!window.Thriv.Sync[syncKey]) {
    window.Thriv.Sync[syncKey] = new SyncManager<ApiResponse<T>>();
  }

  const syncManager = window.Thriv.Sync[syncKey];
  const { emitter } = syncManager;

  // internally, we use the event emitter, but we don't expose it, preferring
  // to manage state the react way
  const [results, setResults] = useState<T>();
  const [status, setStatus] = useState<LoadStatus>('idle');

  useEffect(() => {
    const updateStatus = (newStatus: LoadStatus) => {
      setStatus(newStatus);
    };

    emitter.on(keyStatusChanged, updateStatus);

    return () => {
      if (emitter) {
        emitter.off(keyStatusChanged, updateStatus);
      }
    };
  }, [emitter]);

  useEffect(() => {
    const updateResults = (newResults: T) => {
      setResults(newResults);
    };

    emitter.on(keyValueChanged, updateResults);

    return () => {
      if (emitter) {
        emitter.off(keyValueChanged, updateResults);
      }
    };
  }, [emitter]);

  useEffect(() => {
    const wrapFetch = async () => {
      if (!emitter || fetcher == null) {
        return;
      }
      // must kick off the request in the same event loop
      syncManager.setSyncStatus('loading');
      emitter.emit(keyStatusChanged, 'loading');

      const response = await fetcher();
      if (response.hasError) {
        syncManager.setSyncStatus('error');
      } else {
        syncManager.setSyncStatus('done');
        emitter.emit(keyValueChanged, response.result);
      }
      emitter.emit(keyStatusChanged, syncManager.getSyncStatus());
    };

    // need to check and see if a request is already in-flight.
    if (syncManager.getSyncStatus() !== 'loading') {
      wrapFetch();
    }
  }, [emitter, fetcher, syncManager]);

  return {
    results,
    status,
  };
};
